






















































import { Component, Vue } from 'vue-property-decorator';
import { Entreprise } from '@/api/models/options/entreprises/entreprise';
import { PaginatedList } from '@/api/models/common/paginatedList';
import { VueAutosuggest }  from 'vue-autosuggest';
import {
  BButton, BFormInput, BInputGroupAddon, BInputGroupAppend, BInputGroupPrepend, BInputGroupText
} from 'bootstrap-vue'
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert } from '@/libs/sweetAlerts/alerts';
@Component({
  components: {
    VueAutosuggest,
    BButton,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroupText,
    BInputGroupAddon,
    SearchableVueSelect
  }
})
export default class SearchAccompagnementCollectif extends Vue {
  selected: any = null;

  searchEntreprisesTotalCount = 0;
  searchEntreprisesItems: Entreprise[] = [];
  
  // searchLastEntreprises: PaginatedList<EntrepriseWithLastInterventionModel> | null = null
  // lastLoading = false

  async created() {
    //await this.getLastsEntreprises()
  }

  // async getLastsEntreprises() {
  //   this.lastLoading = true
  //   await this.$http.ressifnet.entreprises
  //     .getLastsForActionCo()
  //     .then((res: PaginatedList<EntrepriseWithLastInterventionModel>) => {
  //       this.lastLoading = false
  //       this.searchLastEntreprises = res
  //     },
  //     (error: any) => {
  //       errorAlert.fire({
  //         title: "Erreur lors du chargement des dernières entreprises consultées",
  //       });
  //     })
  // }

  async searchEntreprise(params: any) {   
    if (this.selected == null || this.selected == undefined){
      if (!params || params?.reset) {
        this.searchEntreprisesTotalCount = 0;
        this.searchEntreprisesItems = [];
      }

      await this.$http.ressifnet.entreprises
        .paginatedList(
          params?.pageNumber ?? 1, 
          params?.pageSize ?? 10, 
          params?.search ?? ""
        )
        .then((response: PaginatedList<Entreprise>) => {
          this.searchEntreprisesTotalCount = response.totalCount;
          this.searchEntreprisesItems = this.searchEntreprisesItems!.concat(response.items.filter(ri => !this.searchEntreprisesItems!.some(lsi => lsi.id == ri.id)))
          this.selected = response.items.length === 1 ? response.items[0] : undefined;
        });
    } 
  }

  goDetails() {
    this.$router.push({ name: 'details-suivi-entreprise', 
      params: { 
        idEntreprise: this.selected.id
      }})
  }
}
